import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { tlds } from '@hapi/tlds';
import { joiResolver } from '@hookform/resolvers/joi';
import axios from 'axios';

const requiredFieldDefault = '{{#label}} is a required field';
const schema = Joi.defaults((schema) =>
    schema.options({
        messages: {
            'string.max': '{{#label}} must not exceed {{#limit}} characters.',
            'any.required': requiredFieldDefault,
            'string.required': requiredFieldDefault,
            'string.empty': requiredFieldDefault,
            'number.required': requiredFieldDefault,
            'number.empty': requiredFieldDefault,
        },
        errors: {
            wrap: {
                label: undefined,
                array: undefined,
            },
        },
    })
).object({
    name: Joi.string().required().label('Name'),
    email: Joi.string()
        .email({ tlds: { allow: tlds } })
        .required()
        .label('Email'),
    phone: Joi.string()
        .allow('')
        .pattern(/^(\+44|0)[\d\s]{10,15}$/)
        .trim()
        .min(10)
        .max(18)
        .label('Phone Number')
        .messages({
            'string.pattern.base': 'Please enter a valid UK telephone number',
            'string.empty': '{{#label}} is required',
            'string.min': '{{#label}} is required',
            'string.max': '{{#label}} cannot exceed 18 characters',
        }),
    message: Joi.string().allow('').label('Message'),
});

const ContactForm = ({ setShowContactForm }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        clearErrors,
        setValue,
    } = useForm({ resolver: joiResolver(schema), mode: 'all' });

    const [formError, setFormError] = useState();
    const [formSuccess, setFormSuccess] = useState(false);

    const clearErrorsOnChange = (event) => {
        const fieldName = event.target.name;
        clearErrors(fieldName);
        setValue(fieldName, event.target.value);
    };

    const onSubmit = async (data) => {
        setFormError(null);
        setFormSuccess(false);

        await window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute(
                // eslint-disable-next-line no-undef
                process.env.REACT_APP_RECAPTCHA_TOKEN,
                { action: 'CONTACT' }
            );
            try {
                await axios.post(
                    // eslint-disable-next-line no-undef
                    process.env.REACT_APP_CONTACT_FORM_POST_URL,
                    { token, ...data }
                );
                setFormSuccess(true);
            } catch (error) {
                console.log(error);
                setFormError(
                    'Something went wrong with your submission, please try again'
                );
            }
        });
    };

    const fieldCSSClass =
        'appearance-none w-full border-1 border-b-3 bg-white rounded-sm px-md focus:border-b-primary focus:outline-none';
    const inputCssClass = `${fieldCSSClass} h-14`;
    const errorCssClass = 'text-highlight my-sm font-bold';

    if (formSuccess) {
        return (
            <div className={'border border-positive px-md py-sm'}>
                {
                    "Thank you for supporting my campaign, I'll be in touch shortly"
                }
            </div>
        );
    }

    return (
        <form
            role="form"
            method="post"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            {formError && <p className={errorCssClass}>{formError}</p>}
            <div className={'mb-sm'}>
                <label className={'block mb-xs font-bold'}>Your Name</label>
                <input
                    className={inputCssClass}
                    {...register('name')}
                    onChange={(event) => clearErrorsOnChange(event)}
                />
                {errors.name && (
                    <p className={errorCssClass}>{errors.name.message}</p>
                )}
            </div>

            <div className={'mb-sm'}>
                <label className={'block mb-xs font-bold'}>Email</label>
                <input
                    className={inputCssClass}
                    onChange={(event) => clearErrorsOnChange(event)}
                    {...register('email')}
                />
                {errors.email && (
                    <p className={errorCssClass}>{errors.email.message}</p>
                )}
            </div>

            <div className={'mb-sm'}>
                <label className={'block mb-xs font-bold'}>Phone Number</label>
                <input
                    className={inputCssClass}
                    {...register('phone')}
                    onChange={(event) => clearErrorsOnChange(event)}
                />
                {errors.phone && (
                    <p className={errorCssClass}>{errors.phone.message}</p>
                )}
            </div>

            <div className={'mb-lg'}>
                <label className={'block mb-xs font-bold'}>
                    Message (optional)
                </label>
                <textarea
                    className={`${fieldCSSClass} h-40 py-md`}
                    {...register('message')}
                    onChange={(event) => clearErrorsOnChange(event)}
                />
                {errors.message && (
                    <p className={errorCssClass}>{errors.message.message}</p>
                )}
            </div>
            <div className={'flex justify-between'}>
                {setShowContactForm && (
                    <button
                        type={'button'}
                        className={
                            'text-white border-2 border border-white min-w-128 bg-transparent'
                        }
                        onClick={(event) => {
                            event.preventDefault();
                            setShowContactForm(false);
                            window.scrollTo(0, 0);
                        }}
                    >
                        Back
                    </button>
                )}
                <div className={'flex justify-end w-full'}>
                    <button type="submit" className={'btn btn-highlight'}>
                        Send
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
